import residential from "../assets/images/projectImages/houseProject.jpg";
import small from "../assets/images/projectImages/smallProject.jpg";
import industrial from "../assets/images/projectImages/industrialProject.jpg";
export const servicesArray = [
  {
    title: "Residential Solar Installations",
    desc: "Our residential solar installations are tailored to meet the unique energy needs of homeowners, providing them with a reliable and sustainable energy source that reduces their dependence on traditional electricity grids. We understand that each residence is distinct, with varying energy consumption patterns and architectural considerations. To address these nuances, our team of solar experts works closely with homeowners from the initial consultation to the final installation.The process begins with an in-depth analysis of the property's energy requirements and consumption patterns. By taking into account factors such as peak energy usage, available roof space, and regional solar irradiation levels, we design a customized solar system that maximizes energy generation and efficiency.Our skilled installation teams execute the project with precision and efficiency, ensuring that the solar panels are positioned optimally to capture the most sunlight throughout the day. We pay meticulous attention to the aesthetics, seamlessly integrating the solar panels into the architecture to enhance the property's overall appearance. The benefits of our residential solar installations are twofold: homeowners experience a significant reduction in their electricity bills, leading to substantial long-term cost savings, and they also contribute to a cleaner environment by reducing their carbon footprint. By choosing solar energy, our clients not only invest in their financial well-being but also embrace sustainable practices that promote a greener and more sustainable future.",
    image: residential,
  },
  {
    title: "Industrial Solar Solutions",
    desc: "In an era when sustainability and environmental consciousness are paramount, industries and commercial establishments are seeking innovative ways to reduce their ecological impact while maintaining profitability. At Solis Energy Solutions, we collaborate with forward-thinking businesses to provide tailored industrial solar solutions that align with their specific energy demands and operational requirements. Our team of engineers and consultants begins the process by conducting a comprehensive energy audit of the facility. By analyzing the energy consumption patterns, peak demand hours, and production schedules, we gain valuable insights that inform the design of the optimal solar system. Using cutting-edge technology and industry best practices, we strategically install solar panels on rooftops, parking structures, and unused land to capitalize on available space and solar exposure. The carefully planned placement ensures maximum solar energy capture throughout the day, optimizing energy production and minimizing dependency on conventional power sources. We understand that for industries, energy cost savings are critical to remaining competitive. Our industrial solar solutions deliver substantial financial benefits through reduced electricity bills, allowing businesses to allocate resources more efficiently and enhance their bottom line. Additionally, by adopting renewable energy, industries take a leadership position in promoting sustainable practices and contribute to a cleaner planet.",
    image: industrial,
  },
  {
    title: "Small-Scale Setups",
    desc: "At Solis Energy Solutions, we firmly believe that solar energy should be accessible to businesses of all sizes. Our small-scale solar setups cater to the needs of shops, offices, schools, and other institutions looking to embrace renewable energy solutions without incurring exorbitant costs. For small businesses and organizations, the decision to go solar is often influenced by budgetary constraints and limited available space. Our team of experts excels at designing innovative solar solutions that maximize energy production within these limitations. We carefully evaluate the available space and recommend the most suitable solar system, ensuring that it aligns with the specific energy demands and budget of the client. The benefits of our small-scale solar setups extend beyond immediate financial savings. By utilizing solar energy, businesses and institutions demonstrate their commitment to sustainability, attracting environmentally conscious customers and stakeholders. Moreover, with reliable and uninterrupted access to clean energy, these entities safeguard themselves against potential energy price fluctuations, providing greater financial stability in the long run. At Solis Energy Solutions, we are dedicated to empowering businesses and individuals alike with the advantages of solar energy. Our commitment to quality, sustainability, and customer satisfaction has earned us a reputation as a trusted solar solutions provider in the region.",
    image: small,
  },
];
