import Consultation from "../assets/images/projectImages/consultation.jpg"
import Customization from "../assets/images/projectImages/customization.jpg"
import Approvals from "../assets/images/projectImages/approval.jpg"
import Installation from "../assets/images/projectImages/install.jpg"
import Testing from "../assets/images/about2.jpg"
import Maintainance from "../assets/images/projectImages/maintain.jpg"
export const solarInstallationApproach = [
    {
      title: "Step 1: Initial Consultation and Assessment",
      desc: "The solar installation process begins with a comprehensive initial consultation and site assessment, forming the foundation of a successful solar journey. Our dedicated solar experts engage in an open dialogue with the client, taking the time to understand their unique energy needs, sustainability goals, and budgetary considerations. This collaborative approach ensures that every aspect of the solar project aligns seamlessly with the client's vision and objectives.\n\nFollowing the consultation, our experienced team conducts an in-depth site assessment. This evaluation encompasses an analysis of the property's solar potential, examining crucial factors such as available roof space, potential shading from nearby structures or vegetation, and the orientation of the site relative to the sun's path throughout the day. Additionally, we consider local solar irradiation levels and historical weather patterns to accurately estimate the solar system's potential output.\n\nBy combining the insights gained from the consultation and site assessment, we provide our clients with a transparent evaluation of the feasibility and viability of their solar installation. Armed with a comprehensive understanding of the project's potential, our clients can make informed decisions about their renewable energy investment.",
      image: Consultation,
    },
    {
      title: "Step 2: Customized System Design",
      desc: "Empowered by the insights from the initial consultation and site assessment, our team of skilled engineers and designers embark on creating a tailor-made solar system design. We believe that no two solar installations are alike, and as such, we approach every project with a commitment to personalized excellence.\n\nOur engineers leverage cutting-edge solar design tools and technology to optimize the layout and configuration of the solar system. By strategically placing solar panels and selecting the most appropriate solar technologies, we maximize energy production while ensuring the aesthetics of the installation seamlessly blend with the property's architecture.\n\nTo provide our clients with a comprehensive overview of the proposed solar system, we utilize advanced energy modeling and simulations. This detailed analysis projects the system's performance over time, estimating energy generation and potential savings. This empowers our clients to make well-informed decisions, understanding the long-term benefits and return on investment they can expect from their solar journey.\n\nWe collaborate closely with our clients throughout the design process, seeking their feedback and input to ensure that the finalized solar system design reflects their unique preferences and energy aspirations.",
      image: Customization,
    },
    {
      title: "Step 3: Permitting and Approvals",
      desc: "Navigating the permitting and approvals process is a crucial step in realizing a successful solar installation. At Solis Green Energy Solutions, we understand the complexities and intricacies of obtaining the necessary permits, and we take the responsibility off our clients' shoulders.\n\nOur experienced team meticulously handles the paperwork and coordination required for permitting and approvals. This includes engaging with local authorities, securing the necessary permits, and ensuring that the installation adheres to building codes and regulations. We also collaborate with utility companies to facilitate grid connection approvals, streamlining the process and expediting project timelines.\n\nBy managing the entire permitting process, we eliminate any potential roadblocks and provide our clients with a smooth and hassle-free experience. Our commitment to transparency ensures that our clients are well-informed at every stage of the permitting process, offering them peace of mind and confidence in their solar investment.",
      image: Approvals,
    },
    {
      title: "Step 4: Professional Installation",
      desc: "With the groundwork laid and the necessary permits in hand, our proficient installation team springs into action, bringing the solar system design to life. Our skilled technicians and installers adhere to industry best practices and rigorous safety standards, ensuring a seamless and efficient installation process.\n\nFrom mounting solar panels on rooftops or ground-mount structures to establishing the necessary electrical connections, we pay meticulous attention to detail at every step of the installation. We embrace the latest solar installation techniques, ensuring that the solar system not only operates optimally but also withstands the test of time and diverse weather conditions.\n\nDuring the installation, we remain respectful of our clients' time and space, striving to minimize disruption to their daily activities. Our goal is to deliver a solar installation that seamlessly integrates with the property, enhancing its visual appeal while contributing to its energy efficiency.\n\nUpon completion of the installation, our team conducts thorough quality checks to ensure that every element of the solar system meets our stringent standards. We believe that a successful solar installation is one that functions flawlessly and is primed for maximum energy generation.",
      image: Installation,
    },
    {
      title: "Step 5: Testing and Commissioning",
      desc: "With the solar panels securely in place, our commitment to excellence continues with rigorous testing and commissioning. We leave no stone unturned in ensuring that the solar system operates at peak performance and efficiency.\n\nOur meticulous testing process involves comprehensive inspections, system checks, and performance evaluations. This ensures that all components of the solar system are functioning correctly and harmoniously. We pay particular attention to the integration of the solar system with the client's existing electrical infrastructure, validating that the transition to solar energy is smooth and seamless.\n\nAs part of the commissioning process, we provide our clients with a detailed overview of the solar system's operation, empowering them to monitor its performance and energy generation. We are committed to delivering complete transparency, enabling our clients to witness the immediate impact of their solar investment and experience the advantages of clean, renewable energy.",
      image: Testing,
    },
    {
      title: "Step 6: Ongoing Maintenance and Support",
      desc: "At Solis Green Energy Solutions, our relationship with our clients extends far beyond the installation phase. We are dedicated to ensuring the long-term success of every solar system we install, providing comprehensive maintenance and support services.\n\nRegular maintenance is essential to maximizing the efficiency and longevity of a solar system. Our team conducts routine inspections and cleaning of solar panels, ensuring that they operate at peak performance. We also offer advanced monitoring services that track the solar system's energy production, detecting and addressing any potential issues promptly.\n\nOur knowledgeable technical support team is always available to answer any questions our clients may have, providing prompt and reliable assistance. We believe that continued client satisfaction is achieved through attentive and responsive support, fostering lasting relationships that stand the test of time.\n\nWith our ongoing maintenance and support, our clients can be confident that their solar investment will continue to deliver energy savings and contribute to a sustainable future.",
      image: Maintainance,
    }
  ];
  