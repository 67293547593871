import { useMediaQuery } from "@mui/material";
import pattern2 from "../../assets/images/pattern2.png";
export const FooterStyles = () => {
  const matches = useMediaQuery("(max-width:825px)");
  const mobileMatch = useMediaQuery("(max-width:600px)");

  return !matches
    ? {
        wrapGridStyles: {
          width: "100%",
          height: "50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          bottom: 0,
        },
        dataGridStyles: {
          width: "100%",
          backgroundImage: `url(${pattern2})`,
          height: "fit-content",
          borderTopRightRadius: "50px",
          borderTopLeftRadius: "50px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "start",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 77px 29px 0px",
          padding: "30px",
        },
        columnGridStyles: {
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "start",
          rowGap: "10px",
          color: "#fff",
          padding: "10px",
        },
        copyRightGridStyles: {
          width: "inherit",
          height: "50px",
          display: "block",
          textAlign: "center",
          alignSelf: "center",
          backgroundColor: "#3c3c3c",
          color: "white",
        },
        socialMediaGrid: {
          width: "inherit",
          height: "50px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          backgroundColor: "transparent",
        },
        imageIconStyle: {
          width: "25px",
          height: "25px",
        },
        typographyStyles: {
          color: "rgb(255,255,255)",
          fontSize: "20px",
          fontWeight: "bold",
          marginBottom: "10px",
        },
        linkStyles: {
          textDecoration: "none",
          color: "#fff",
        },
        mobileButtonBoxStyle: {
          display: "none",
        },
      }
    : !mobileMatch
    ? {
        wrapGridStyles: {
          width: "100%",
          height: "fit-content",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
          alignContent: "start",
          justifyItems: "start",
        },
        dataGridStyles: {
          width: "100%",
          backgroundImage: `url(${pattern2})`,
          height: "fit-content",
          borderTopRightRadius: "50px",
          borderTopLeftRadius: "50px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "start",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 77px 29px 0px",
          bottom: 0,
          padding: "20px",
        },
        columnGridStyles: {
          height: "100%",
          display: "flex",
          maxWidth: "150px",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "start",
          rowGap: "10px",
          color: "#fff",
          padding: "10px",
          fontSize: "12px",
        },
        copyRightGridStyles: {
          width: "inherit",
          height: "50px",
          display: "block",
          bottom: "0",
          textAlign: "center",
          alignSelf: "center",
          backgroundColor: "#3c3c3c",
          color: "white",
          fontSize: "12px",
        },
        typographyStyles: {
          color: "rgb(255,255,255)",
          fontSize: "16px",
          fontWeight: "bold",
        },
        subTypographyStyles: {
          color: "rgb(255,255,255)",
          fontSize: "12px",
          fontWeight: "normal",
        },
        socialSubTypographyStyles: {
          color: "#3c3c3c",
          fontSize: "12px",
          fontWeight: "normal",
        },
        linkStyles: {
          textDecoration: "none",
          color: "#fff",
        },
        mobileButtonBoxStyle: {
          display: "none",
        },
        imageIconStyle: {
          width: "15px",
          height: "15px",
        },
        socialMediaGrid: {
          width: "inherit",
          height: "fit-content",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "transparent",
          color: "#3c3c3c !important",
          padding:"10px 0px"
        },
      }
    : {
        wrapGridStyles: {
          width: "100%",
          height: "fit-content",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
          alignContent: "start",
          justifyItems: "start",
        },
        dataGridStyles: {
          width: "100%",
          backgroundImage: `url(${pattern2})`,
          height: "150px",
          borderTopRightRadius: "50px",
          borderTopLeftRadius: "50px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "start",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 77px 29px 0px",
          bottom: 0,
          padding: "20px",
        },
        columnGridStyles: {
          height: "100%",
          display: "none",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "start",
          rowGap: "10px",
          color: "#fff",
          padding: "10px",
          fontSize: "12px",
        },
        copyRightGridStyles: {
          width: "inherit",
          height: "50px",
          display: "block",
          bottom: "0",
          textAlign: "center",
          alignSelf: "center",
          backgroundColor: "#3c3c3c",
          color: "white",
          fontSize: "12px",
        },
        typographyStyles: {
          color: "rgb(255,255,255)",
          fontSize: "16px",
          fontWeight: "bold",
        },
        subTypographyStyles: {
          color: "rgb(255,255,255)",
          fontSize: "12px",
          fontWeight: "normal",
        },
        socialSubTypographyStyles: {
          color: "#3c3c3c",
          fontSize: "12px",
          fontWeight: "normal",
        },
        linkStyles: {
          textDecoration: "none",
          color: "#fff",
        },
        mobileButtonBoxStyle: {
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "transparent",
          color: "#fff",
        },
        imageIconStyle: {
          width: "25px",
          height: "25px",
        },
        socialMediaGrid: {
          width: "inherit",
          height: "fit-content",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "transparent",
          color: "#3c3c3c !important",
          padding:"10px 0px"
        },
      };
};
